import React, {useState} from 'react'
import {Grid, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {Inventory, QuestionAnswer} from '@mui/icons-material'
import SettingsIcon from '@mui/icons-material/Settings'
import WbModal from '../../../common/WbModal'
import WbButton from '../../../common/WbButton'
import WbOutlineButton from '../../../common/WbOutlineButton'

export default function QuestionToolbar({view, setView, onScreenChange}) {
  const [alert, setAlert] = useState(false)
  const [temp, setTemp] = useState(false)

  const getAlert = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>Are you sure you want to leave this page?</p>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} textAlign="end">
          <WbOutlineButton
            CustomOutlineButtonText={'Cancel'}
            onClick={() => setAlert(false)}
          />{' '}
          <WbButton
            CustomButtonText={'Leave'}
            onClick={() => {
              setAlert(false)
              setView(temp)
            }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <div className="side-nav">
      <ToggleButtonGroup
        exclusive
        value={view}
        onChange={(event, newValue) => {
          debugger
          if (newValue === 'settings' && view === 'main') {
            if (onScreenChange()) {
              setAlert(true)
              setTemp(newValue)
              return
            }
          }
          setView(newValue)
          setTemp('')
        }}
        orientation="vertical"
      >
        {alert && (
          <WbModal
            title={'Unsaved Changes'}
            content={getAlert()}
            onClose={() => setAlert(false)}
          />
        )}
        <ToggleButton value="main">
          <QuestionAnswer></QuestionAnswer>
          <span>Your Questions</span>
        </ToggleButton>

        <ToggleButton value="library">
          <Inventory></Inventory> <span>Question Bank</span>
        </ToggleButton>

        <ToggleButton value="settings">
          <SettingsIcon></SettingsIcon>
          <span>Survey Settings</span>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}
