import React from 'react'
import WbButton from '../../../components/common/WbButton'
import WbOutlineButton from '../../../components/common/WbOutlineButton'
import {Button} from '@mui/material'
import WbModal from '../../../components/common/WbModal'
import OverlappingCycles from './OverlappingCycles'

export default function OverLappingAlert({cycles, onProceed, onCancel}) {
  const [showDetails, setShowDetails] = React.useState(false)
  return (
    <>
      {showDetails && (
        <WbModal
          onClose={() => setShowDetails(false)}
          title="Survey Conflict Details"
          content={<OverlappingCycles cycles={cycles} />}
        />
      )}

      <p>
        There is some conflict while scheduling this survey
        <Button onClick={e => setShowDetails(true)}>View Details</Button>
      </p>
      <p>Do you want to continue?</p>
      <div style={{textAlign: 'right'}}>
        <WbOutlineButton
          CustomOutlineButtonText={'Cancel'}
          onClick={onCancel}
        />{' '}
        <WbButton CustomButtonText={'Proceed'} onClick={onProceed}></WbButton>
      </div>
    </>
  )
}
