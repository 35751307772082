export default {
  dashboard: '/',
  createSurvey: '/survey-panel',
  questionPanel: '/survey-panel/questions/:token',
  surveyTemplatesPanel: '/survey-templates-panel/:audience/:anonimous/:shared',
  //surveyTemplatesPanelPreview: '/survey-templates-panel/preview/:id',
  invitesPanel: '/survey-panel/invites/:token',
  schedulePanel: '/survey-panel/schedule/:token',
  // categories: '/question-panel/categories',
  // subcategories: '/question-panel/sub-categories',
  question: '/super-admin/question',
  surveyView: '/survey-view/:aud/:token',
  surveyParticipants: '/survey-panel/participants/:token',
  resultsDash: '/survey-results',
  comments: '/survey-results/comments',
  login: '/login',
  loginRedirect: '/login-redirect',
  participantsReply: '/replies/:commentId',
  questiontag: '/super-admin/question-tags',
  superAdminRedirect: '/super-admin/redirect',
}
