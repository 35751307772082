import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useSelector} from 'react-redux'

const ProtectedRoute = () => {
  const skipStorage = ['/login', '/login-redirect', '/']
  if (!skipStorage.includes(window.location.pathname)) {
    var url = window.location.pathname + window.location.search
    localStorage.setItem('RedirectUrl', url)
  }
  const user = useSelector(state => state.auth.user)
  return user ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute
