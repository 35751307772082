import React, {useEffect} from 'react'
import groupCyclesApi from '../../services/api/surveys/groupCyclesApi'
import {Done, Repeat, Schedule, BarChart} from '@mui/icons-material'
import HistoryIcon from '@mui/icons-material/History'
import WbModal from '../../components/common/WbModal'
import CloseIcon from '@mui/icons-material/Close'
import WbAlert from '../../components/common/WbAlert'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  Button,
  Grid,
  Skeleton,
} from '@mui/material'
import {styled} from '@mui/material/styles'

const HeaderTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#45338C',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '.schedule-survey-btn': {
    color: '#E84F6B',
  },
  '.schedule-survey-btn:hover': {
    backgroundColor: 'transparent',
  },
}))

export default function GroupCyclesList({token, resultId, loadSurveys}) {
  const [showCycles, setShowCycles] = React.useState(false)
  const [groupCycles, setGroupCycles] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    setLoading(true)
    groupCyclesApi
      .getBySurveyToken(token)
      .then(res => {
        debugger
        setGroupCycles(res.groupCycles)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [token])

  const closeGroupCycle = id => {
    groupCyclesApi
      .CloseGroupCycleById(id)
      .then(res => {
        if (res) {
          loadSurveys()
          // groupCyclesApi
          //   .getBySurveyToken(token)
          //   .then(res => {
          //     setGroupCycles(res.groupCycles)
          //   })
          //   .finally(() => {
          //     setLoading(false)
          //   })
        } else {
          WbAlert({message: 'Failed to close group cycle', type: 'error'})
        }
      })
      .catch(() => {})
      .finally(() => {})
  }

  const GroupCycleCard = ({groupCycle}) => {
    return (
      <TableBody>
        <StyledTableRow>
          <TableCell>
            {groupCycle.status === 4 ? (
              <Done style={{color: '#007A7A'}} />
            ) : groupCycle.status === 3 ? (
              <Repeat style={{color: '#E84F6B'}} />
            ) : groupCycle.status === 2 ? (
              <Schedule style={{color: '#FF5939'}}></Schedule>
            ) : null}
          </TableCell>
          <TableCell>{groupCycle.label}</TableCell>
          <TableCell>
            {new Date(groupCycle.startDate).toLocaleDateString()}
          </TableCell>
          <TableCell>
            {new Date(groupCycle.endDate).toLocaleDateString()}
          </TableCell>
          <TableCell>
            {groupCycle.status === 3 ? (
              <Button
                className="schedule-survey-btn"
                startIcon={<CloseIcon />}
                style={{textTransform: 'none'}}
                onClick={() => closeGroupCycle(groupCycle.id)}
                disableElevation
                disableRipple
                disableFocusRipple
              >
                Close
              </Button>
            ) : groupCycle.status === 4 ? (
              <Button
                className="schedule-survey-btn"
                startIcon={<BarChart />}
                style={{textTransform: 'none'}}
                onClick={e =>
                  window.open(
                    `https://ei.welbee.co.uk/EDUDashboard?CycleId=${groupCycle.id}`,
                    '_blank'
                  )
                }
                disableElevation
                disableRipple
                disableFocusRipple
              >
                Analyse Data
              </Button>
            ) : null}
          </TableCell>
        </StyledTableRow>
      </TableBody>
    )
  }

  return (
    <div>
      {/* <Checklist
        style={{color: '#E84F6B', cursor: 'pointer'}}
        onClick={() => setShowCycles(true)}
      /> */}
      <Button
        className="schedule-data-btn"
        startIcon={<HistoryIcon />}
        style={{textTransform: 'none'}}
        onClick={() => setShowCycles(true)}
        disableElevation
        disableRipple
        disableFocusRipple
      >
        Survey Schedule
      </Button>
      {showCycles && (
        <WbModal
          title="Survey Schedule"
          content={
            loading ? (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            ) : (
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <HeaderTableCell>Status</HeaderTableCell>
                        <HeaderTableCell>Label</HeaderTableCell>
                        <HeaderTableCell>Start Date</HeaderTableCell>
                        <HeaderTableCell>End Date</HeaderTableCell>
                        <HeaderTableCell>Action</HeaderTableCell>
                      </TableRow>
                    </TableHead>
                    {/* <GroupCycleCard renderHeader={true}></GroupCycleCard> */}
                    {groupCycles?.map(groupCycle => (
                      <GroupCycleCard
                        key={groupCycle.id}
                        groupCycle={groupCycle}
                      />
                    ))}
                  </Table>
                </Grid>
              </Grid>
            )
          }
          onClose={() => setShowCycles(false)}
        />
      )}
    </div>
  )

  //   return loading ? (
  //     <div className="loading">
  //       <CircularProgress />
  //     </div>
  //   ) : (
  //     <div className="group-cycles-list">
  //       {groupCycles.map(groupCycle => (
  //         <GroupCycleCard key={groupCycle.id} groupCycle={groupCycle} />
  //       ))}
  //     </div>
  //   )
}
