import {DragIndicator} from '@mui/icons-material'
import {Divider} from '@mui/material'
import {parseInt} from 'lodash'
import React from 'react'

export default function QuestionRow({text, index, onChange, onReorder}) {
  return (
    <div
      className="questions-list"
      onClick={e => onChange(index)}
      draggable
      onDragStart={e => {
        e.target.style.cursor = 'grabbing'
        e.dataTransfer.setData('text/plain', index)
      }}
      onDragOver={e => {
        e.preventDefault()
        e.target.style.cursor = 'grabbing'
      }}
      onDragEnter={e => {
        e.preventDefault()
        e.target.style.cursor = 'grabbing'
        e.target.style.opacity = '0.5'
      }}
      onDragLeave={e => {
        e.preventDefault()
        e.target.style.cursor = 'grab'
        e.target.style.opacity = '1'
      }}
      onDrop={e => {
        e.preventDefault()
        const from = parseInt(e.dataTransfer.getData('text/plain'))
        const to = index
        e.target.style.cursor = 'grab'
        e.target.style.opacity = '1'
        onReorder(from, to)
      }}
    >
      <DragIndicator sx={{fontSize: '20px'}} style={{cursor: 'grab'}} />
      <div className="question-no">{index + 1}</div>
      <Divider orientation="vertical" variant="middle" flexItem />{' '}
      <div className="question-txt">{text}</div>
    </div>
  )
}
