import {
  Grid,
  InputAdornment,
  Stack,
  FormControlLabel,
  Button,
  Modal,
  Box,
  Skeleton,
  Alert
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import surveyTemplatesApi from '../../services/api/surveyTemplates/surveyTemplatesApi'
import {Search} from '@mui/icons-material'
import {useNavigate, useParams} from 'react-router-dom'
import paths from '../../routes/paths'
import SurveyTemplatePreview from './SurveyTemplatePreview'
import WbTextField from '../../components/common/WbTextField'
import WbSwitch from '../../components/common/WbSwitch'
import WbOutlineButton from '../../components/common/WbOutlineButton'
import WestIcon from '@mui/icons-material/West'
import {Config} from '../../utils/Config'
import useCustomerData from '../../hooks/useCustomerData'
import {CUSTOMER_TYPE} from '../../utils/constants'

export default function SurveyTemplates() {
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [showTemplates, setShowTemplates] = useState(true)
  const [showPrevious, setShowPrevious] = useState(false)
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [loadingTags, setLoadingTags] = useState(false)

  const navigate = useNavigate()
  const [tags, setTags] = useState([])
  const customer = useCustomerData()
  const {audience, anonimous, shared} = useParams()

  const colors = ['pink', 'blue', 'green', 'yellow', 'purple', 'orange']
  const images = ['checkbox', 'radio', 'text', 'rating']

  useEffect(() => {
    setLoading(true)
    setLoadingTags(true)

    surveyTemplatesApi
      .get(customer.id, customer.customerType)
      .then(res => {
        let audiences = []
        if (audience === '1') {
          audiences = [1, 3, 5, 7]
        }
        if (audience === '2') {
          audiences = [2, 3, 6, 7]
        }
        if (audience === '3') {
          audiences = [3, 7]
          //audiences = [1, 2, 3, 7]
        }
        if (audience === '4') {
          audiences = [4, 5, 6, 7]
        }
        if (audience === '5') {
          //audiences = [3, 5, 7]
          audiences = [5, 7]
        }
        if (audience === '6') {
          //audiences = [5, 6, 7]
          audiences = [6, 7]
        }
        if (audience === '7') {
          audiences = [7]
        }
        res = res.filter(x => audiences.includes(x.audience))

        let temp = res.sort((a, b) => a.name.localeCompare(b.name))
        setTemplates(temp)
        temp = temp.filter(x => x.schoolId === 0 && x.matId === 0)
        let t = []
        res.map(survey => {
          survey.surveysTags?.map(tag => {
            if (!t.find(x => x.tag === tag)) t.push({tag: tag})
          })
        })
        setTags(t.sort((a, b) => a.tag.localeCompare(b.tag)))
        setFiltered(temp)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
        setLoadingTags(false)
      })
  }, [])

  useEffect(() => {
    filterTemplates()
  }, [showPrevious, showTemplates, search, selectedTag])

  const copyTemplate = id => {
    surveyTemplatesApi
      .copyTemplate(
        customer.id,
        customer.customerType,
        id,
        anonimous === 'true',
        shared === 'true',
        audience
      )
      .then(response =>
        navigate(
          paths.questionPanel.replace(
            ':token',
            encodeURIComponent(response.token)
          )
        )
      )
      .catch(error => {})
      .finally(() => {})
  }

  const filterTemplates = () => {
    let temp = templates

    if (search !== '') {
      temp = temp?.filter(x =>
        x.name.toLowerCase().includes(search.toLowerCase())
      )
    }
    if (selectedTag) {
      temp = temp?.filter(x => x.surveysTags?.indexOf(selectedTag.tag) > -1)
    }
    let defTemp = []
    if (showTemplates) {
      defTemp = temp?.filter(x => x.schoolId === 0 && x.matId === 0)
    }
    let cusTemp = []
    if (showPrevious) {
      let matId = customer.customerType === CUSTOMER_TYPE.MAT ? customer.id : 0
      let schoolId =
        customer.customerType === CUSTOMER_TYPE.School ? customer.id : 0
      cusTemp = temp?.filter(
        x =>
          x.schoolId === schoolId && x.matId === matId && x.templateId === x.id
      )
    }

    setFiltered([...defTemp, ...cusTemp])
  }

  return (
    <div className="dashboard-container">
      <div className="left-container">
        <div className="template-tags">
          <h3>Topics</h3>
          <ul>
            {loadingTags ? (
              <>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>                  
              </>
            ) : (
              tags.map((tag, index) => (
                <li
                  key={index}
                  style={{
                    color: selectedTag === tag ? 'black' : 'black',
                    backgroundColor: selectedTag === tag ? '#EDE7F6' : 'white',
                  }}
                  onClick={() =>
                    selectedTag?.tag === tag.tag
                      ? setSelectedTag(null)
                      : setSelectedTag(tag)
                  }
                >
                  {tag.tag}
                </li>
              ))
            )}
          </ul>
        </div>
        <div className="template-back-btn">
          <WbOutlineButton
            CustomOutlineButtonText="Back"
            onClick={() => navigate(paths.dashboard)}
            sx={{width: '250px'}}
            startIcon={<WestIcon />}
          />
        </div>
      </div>
      <div className="right-container">
        <div className="search-template">
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h1>Survey Library</h1>
              <p>
                Select one of our survey templates, or re-use one of your
                previous surveys.
              </p>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <WbTextField
                label="Search template"
                value={search}
                onChange={e => setSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="end">
                    <Search></Search>
                  </InputAdornment>
                }
              ></WbTextField>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <FormControlLabel
                control={
                  <WbSwitch
                    checked={showPrevious}
                    onChange={e => setShowPrevious(!showPrevious)}
                  />
                }
                label="Show previously used surveys"
              />
              <FormControlLabel
                control={
                  <WbSwitch
                    checked={showTemplates}
                    onChange={e => setShowTemplates(e.target.checked)}
                  />
                }
                label="All templates"
              />
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="template-list">
              <ul>
                {loading ? (
                  <Grid item lg={12} md={12} sm={12} xs={12} my={5} mx={5}>                  
                    <Skeleton animation="wave"/>
                    <Skeleton animation="wave"/>
                    <Skeleton animation="wave"/>                  
                  </Grid>
                ) : filtered.length === 0 ? (
                  <Grid item lg={12} md={12} sm={12} xs={12} my={5} mx={4}>
                    <Alert severity="info">No templates found</Alert>
                  </Grid>
                ) : (
                  filtered.map((template, index) =>
                    template.surveyQuestions?.length > 0 ? (
                      <li key={index}>
                        <div className="template">
                          <div className="template-header relative">
                            <div
                              className={`${colors[index >= 5 ? index % 5 : index]} inner-padding relative template-width`}
                            >
                              <img
                                src={`${Config.BASE_URL}/Artifact/download?fileName=temp-${images[index > 3 ? (index % 5 > 3 ? 0 : index % 5) : index]}.png`}
                                alt={images[index > 3 ? index % 3 : index]}
                              />
                            </div>
                            <div className="template-overlay">
                              <Stack direction="column" spacing={2}>
                                <Button
                                  variant="contained"
                                  onClick={() => copyTemplate(template.id)}
                                  className="use-template-btn"
                                >
                                  Use template
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => setSelectedTemplate(template)}
                                  className="preview-btn"
                                >
                                  Preview template
                                </Button>
                              </Stack>
                            </div>
                          </div>

                          <div className="template-content">
                            <h4>{template.name}</h4>
                          </div>

                          <div className="template-footer">
                            {template.surveyQuestions?.length} questions
                          </div>
                        </div>
                      </li>
                    ) : null
                  )
                )}
              </ul>
            </div>
          </Grid>
          <Modal
            open={selectedTemplate}
            onClose={() => setSelectedTemplate(null)}
          >
            <Box sx={{width: '100%', height: '100%', backgroundColor: 'white'}}>
              <SurveyTemplatePreview
                template={{
                  ...selectedTemplate,
                }}
                onCopy={() => copyTemplate(selectedTemplate.id)}
                onClose={() => setSelectedTemplate(null)}
              ></SurveyTemplatePreview>
            </Box>
          </Modal>
        </Grid>
      </div>
    </div>
  )
}
