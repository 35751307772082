import React, {useEffect, useState} from 'react'
import {schedulerApi} from '../../../services'
import {useNavigate, useParams} from 'react-router-dom'
import WbButton from '../../common/WbButton'
import WbAlert from '../../common/WbAlert'
import paths from '../../../routes/paths'

export default function PublishButton({survey, onSave}) {
  const navigate = useNavigate()
  const token = useParams().token
  const [canPulish, setCanPublish] = useState(false)

  useEffect(() => {
    schedulerApi.canPublish(token).then(res => setCanPublish(res))
  }, [survey])

  const publish = () => {
    schedulerApi
      .publish(token)
      .then(res => {
        WbAlert({message: 'Survey published successfully', type: 'success'})
        navigate(paths.dashboard)
      })
      .catch(err => {
        WbAlert({message: 'Failed to publish survey', type: 'error'})
      })
  }

  return canPulish ? (
    <WbButton
      CustomButtonText={'Publish'}
      onClick={async () => {
        if (onSave) {
          var done = await onSave()
          if (done) {
            publish()
          }
        } else {
          publish()
        }
      }}
    />
  ) : (
    ''
  )
}
