import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {tableCellClasses} from '@mui/material/TableCell'

const HeaderTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#45338C',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
export default function OverlappingCycles({cycles}) {
  return (
    <TableContainer className="participant-list">
      <Table size="small">
        <TableHead>
          <TableRow>
            <HeaderTableCell>Survey Name</HeaderTableCell>
            <HeaderTableCell>Audience</HeaderTableCell>
            <HeaderTableCell>Launch Date</HeaderTableCell>
            <HeaderTableCell>End Date</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cycles?.map((cycle, index) => (
            <StyledTableRow key={index}>
              <TableCell>{cycle.name}</TableCell>
              <TableCell>{cycle.audience}</TableCell>
              <TableCell>
                {new Date(cycle.launchDateTime).toDateString()}
              </TableCell>
              <TableCell>
                {new Date(cycle.endDateTime).toDateString()}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
