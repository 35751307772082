import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { HeadProvider, Title, Link, Meta } from 'react-head';
import { Provider } from 'react-redux';
import {store} from './redux/store';

const theme = createTheme({
    typography: {
        fontFamily: ['Inter, sans-serif'].join(','),
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HeadProvider>
    <meta charset="UTF-8" />
    <script src="https://cdn.jsdelivr.net/npm/@supabase/supabase-js"></script>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </HeadProvider>
)
