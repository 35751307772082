import React from 'react'
import participantsApi from '../../services/api/participants/participantsApi'
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
} from '@mui/material'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  tableCellClasses,
  Grid,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import WbTextField from '../../components/common/WbTextField'
import WbCheckbox from '../../components/common/WbCheckbox'
import WbButton from '../../components/common/WbButton'
import {surveysApi} from '../../services'
import WbAlert from '../../components/common/WbAlert'
import {set} from 'lodash'

const HeaderTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#45338C',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function ResendInvites({survey}) {
  const [participants, setParticipants] = React.useState([])
  const [loadingStaff, setLoadingStaff] = React.useState(false)
  const [pages, setPages] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const [pageData, setPageData] = React.useState([])
  const [all, setAll] = React.useState(true)
  const [selected, setSelected] = React.useState([])
  const [sending, setSending] = React.useState(false)
  const [data, setData] = React.useState([])

  const getPageData = () => {
    setPageData(participants.slice((page - 1) * 10, page * 10))
  }
  React.useEffect(() => {
    getPageData()
  }, [page])
  React.useEffect(() => {
    participantsApi
      .getStaffByFilter(survey.participantFiler)
      .then(response => {
        var list = JSON.parse(response)
        list = list.sort((a, b) => {
          if (a.firstName < b.firstName) {
            return -1
          }
          if (a.firstName > b.firstName) {
            return 1
          }
          return 0
        })
        setData(list)
        setParticipants(list)
        setSelected(list)
        setPages(Math.ceil(JSON.parse(response).length / 10))
        setPageData(list.slice((page - 1) * 10, page * 10))
      })
      .catch(error => console.log(error))
      .finally(() => setLoadingStaff(false))
  }, [survey])

  const sendInvites = () => {
    setSending(true)
    let payload = {
      token: survey.token,
      audience: 1,
      participants: selected,
    }
    surveysApi
      .resendInvites(payload)
      .then(res => {
        WbAlert({message: 'Invites sent successfully', type: 'success'})
      })
      .catch(err => {
        WbAlert({message: 'Failed to send invites', type: 'error'})
      })
      .finally(() => {
        setSending(false)
        setSelected([])
      })
  }

  return loadingStaff ? (
    <CircularProgress />
  ) : (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
        <WbTextField
          label="Search"
          onChange={e => {
            if (e.target.value === '') {
              setParticipants(data)
              setPages(Math.ceil(data.length / 10))
              setPageData(data.slice((page - 1) * 10, page * 10))
            } else {
              let filtered = data.filter(
                x =>
                  x.firstName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  x.lastName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
              )
              setParticipants(filtered)
              setPages(Math.ceil(filtered.length / 10))
              setPageData(filtered.slice((page - 1) * 10, page * 10))
            }
          }}
        />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TableContainer className="participant-list">
          <Table size="small">
            <TableHead>
              <TableRow>
                <HeaderTableCell>
                  <FormControlLabel
                    control={
                      <WbCheckbox
                        checked={all}
                        onChange={e => {
                          setAll(e.target.checked)
                          if (e.target.checked) {
                            setSelected(participants)
                          } else {
                            setSelected([])
                          }
                        }}
                      />
                    }
                    label={'First Name'}
                  />
                </HeaderTableCell>
                {/* <HeaderTableCell>First Name</HeaderTableCell> */}
                <HeaderTableCell>Last Name</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData?.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <WbCheckbox
                          checked={
                            selected.filter(x => x.id === item.id).length > 0
                              ? true
                              : false
                          }
                          onChange={e => {
                            if (e.target.checked) {
                              let l = [...selected, item]
                              setSelected(l)
                              if (l.length === participants.length) {
                                setAll(true)
                              }
                            } else {
                              setSelected(
                                selected.filter(x => x.id !== item.id)
                              )
                              setAll(false)
                            }
                          }}
                        />
                      }
                      label={item.firstName}
                    />
                  </TableCell>
                  {/* <TableCell>{item.firstName}</TableCell> */}
                  <TableCell>{item.lastName}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {pages > 1 && (
          <div className="pagination">
            {Array.from({length: pages}, (_, i) => (
              <div
                className="page-no"
                style={{
                  color: page === i + 1 ? '#ffffff' : '#000000',
                  backgroundColor: page === i + 1 ? '#53B5E0' : '#EDE7F6',
                }}
                key={i}
                onClick={() => setPage(i + 1)}
              >
                {i + 1}
              </div>
            ))}
          </div>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} textAlign="end">
        <WbButton
          CustomButtonText={'Send Invites'}
          onClick={sendInvites}
          disabled={sending}
        />
      </Grid>
    </Grid>
  )
}
