import React from 'react'
import WbButton from '../../components/common/WbButton'

export default function WelcomeScreen({setSurveyStart, name}) {
  return (
    <div className="welcome">
      <h1>{name}</h1>
      <div>
        <h2>Welcome to the survey</h2>
        <p>Please answer the questions as honestly as possible.</p>
      </div>
      <WbButton
        onClick={() => {
          setSurveyStart(true)
        }}
        CustomButtonText={'Start Survey'}
      ></WbButton>
    </div>
  )
}
