import React, {useEffect} from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  tableCellClasses,
} from '@mui/material'
import {styled} from '@mui/material/styles'

const HeaderTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#45338C',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function ParticipantList({list}) {
  const pages = Math.ceil(list.length / 15)
  const [page, setPage] = React.useState(1)
  const [pageData, setPageData] = React.useState([])

  const getPageData = () => {
    setPageData(list.slice((page - 1) * 10, page * 10))
  }
  useEffect(() => {
    getPageData()
  }, [page])

  return (
    <>
      <TableContainer className="list-container">
        <Table size="small">
          <TableHead>
            <TableRow>
              <HeaderTableCell>First Name</HeaderTableCell>
              <HeaderTableCell>Last Name</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData?.map((item, index) => (
              <StyledTableRow key={index}>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pages > 1 && (
        <div className="pagination">
          {Array.from({length: pages}, (_, i) => (
            <div className="page-no"
              style={{                
                color: page === i + 1 ? '#ffffff' : '#000000',
                backgroundColor: page === i + 1 ? '#53B5E0' : "#EDE7F6",
              }}
              key={i}
              onClick={() => setPage(i + 1)}
            >
              {i + 1}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
